import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import '../assets/css/Form.css';
import {Link} from "react-router-dom";
import {AppFooter} from "../AppFooter";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "../Constants";
import AuthService from "../services/auth.service";

export const Register = (props) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const defaultValues = {
        givenName: '',
        familyName: '',
        email: '',
        password: '',
        date: null,
        accept: false
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = async (data) => {
        setLoading(true);
        setFormData(data);
        await AuthService.register(data.givenName, data.familyName, data.email, data.password, data.date)
            .then(() => {
                setShowMessage(true);
                setLoading(false);
                reset();
            })
            .catch((error) => {
                setLoading(false);
            });

    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="form-holder">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account is registered under name <b>{formData.givenName} {formData.familyName}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> for activation instructions.
                    </p>
                </div>
            </Dialog>

            <div className="flex justify-content-center">
                <div className="card">
                    <img src={t('logo_vertical_url')} alt="CarFile logo" height={250} className="marginAuto" />
                    <h1 className="text-900 text-2xl">{t('registration')}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="givenName" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="givenName" className={classNames({ 'p-error': errors.name })}>{t('first_name')}*</label>
                            </span>
                            {getFormErrorMessage('givenName')}
                        </div>
                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="familyName" control={control} rules={{ required: 'Family Name is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('last_name')}*</label>
                            </span>
                            {getFormErrorMessage('familyName')}
                        </div>
                        <div className="field floatNone">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="email" control={control}
                                            rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email*</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <Password id={field.name} {...field} toggleMask disabled={loading}
                                              className={classNames({ 'p-invalid': fieldState.invalid })}
                                              header={passwordHeader} footer={passwordFooter} />
                                )} />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>{t('password')}*</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="field-checkbox" style={{textAlign:"center"}}>
                            <div style={{marginLeft:"auto",marginRight:"auto"}}>
                                <Controller name="accept" control={control} rules={{ required: true }}
                                            render={({ field, fieldState }) => (
                                    <Checkbox inputId={field.name} checked={field.value} disabled={loading}
                                              onChange={(e) => field.onChange(e.checked)}
                                              className={classNames({ 'p-invalid': fieldState.invalid })}/>
                                )} />
                                <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}> I agree to the terms and conditions*</label>
                            </div>
                        </div>
                        <div className="p-field">
                            <span className="p-float-label p-input-icon-right">
								<Controller name="captcha" control={control}
                                            rules={{ required: 'Re Captcha is required.'}}
                                            render={({ field, fieldState }) => (
                                                <ReCAPTCHA id={field.name} {...field}
                                                           sitekey={RECAPTCHA_SITE_KEY}
                                                           className={classNames({ 'p-invalid': fieldState.invalid })}/>
                                            )} />
							</span>
                            {getFormErrorMessage('captcha')}
                        </div>

                        <div className="field">
                            <Button type="submit" label={t('registration')} className="mt-2"
                                    loading={loading} onClick={handleSubmit(onSubmit)}/>
                        </div>
                        <div className="field">
                            <div className="forgot-password wid40 floatLeft">
                                <a className="text-600 font-medium no-underline cursor-pointer textDecorationNone" href="/forgotPassword">{t('forgot_password')}</a>
                            </div>
                            <div className="wid60 floatLeft">
                                <Link to="/login" className="textDecorationNone"><Button type="button" label="Go to Login" className="p-button"
                                        style={{backgroundColor:"var(--pink-400)", borderColor:"var(--pink-400)"}} />
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <AppFooter layoutColorMode='light'/>
        </div>
    );
}
