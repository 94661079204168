import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect, useRef, useState} from "react";
import {OccurrenceService} from "../../services/OccurrenceService";
import dateFormat from "dateformat";
import {DATE_FORMAT} from "../../Constants";
import {useTranslation} from "react-i18next";

export const OccurrenceDataTable = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [occurrenceList, setOccurrenceList] = useState([]);
    const isMounted = useRef(false);
    const layout = 'list';

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const occurrenceService = new OccurrenceService();
            occurrenceService.getList()
                .then(response => {
                    setOccurrenceList(response.data);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const numberBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div className="product-name">{rowData.number}</div>
                </div>
            </React.Fragment>
        );
    }

    const subjectBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div className="product-name">{rowData.subject}</div>
                </div>
            </React.Fragment>
        );
    }

    const createdOnBody = (rowData) => {
        return (
            <React.Fragment>
                <span>{dateFormat(rowData.createdOn, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const categoryNameBody = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.categoryName}</div>
            </React.Fragment>
        );
    }

    return (
        <DataTable value={occurrenceList} layout={layout} paginator rows={10} loading={loading}>
            <Column field="number" body={numberBody} header={t('number')}/>
            <Column field="number" body={subjectBody} header={t('subject')}/>
            <Column field="createdOn" body={createdOnBody} style={{ textAlign: "right" }} header={t('created_on')}/>
            <Column field="createdOn" body={createdOnBody} style={{ textAlign: "right" }} header={t('dead_line')}/>
            <Column field="categoryName" body={categoryNameBody} style={{ textAlign: "right" }} header={t('category')}/>
        </DataTable>
    );
}