// export const BASE_DOMAIN = "https://dev.carfile.app/"
// export const BASE_DOMAIN = "https://stage.carfile.app/"
export const BASE_DOMAIN = "http://localhost:8080/"
// export const BASE_DOMAIN = "http://192.168.100.49:8080/"
export const DATE_FORMAT = "dd.mm.yyyy HH:MM:ss"
export const accessTokenUri = BASE_DOMAIN + 'auth/oauth/token';
export const authorizationUri = BASE_DOMAIN + 'auth/oauth/token';
export const clientId = 'carfileFrontendClient';
export const clientSecret = 'q7wqLbZUtmU3URWhzx47LKzfkfbvvUPmHs6hcwe2WhrqJ9cn92evvQJtJ3LEtLSP';
export const DEBUG = true;
export const layoutColorMode = 'light';