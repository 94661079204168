import React from 'react';
import packageJson from '../package.json';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === 'light' ? 'assets/images/Car and File v5.7 - Black.png' : 'assets/images/logo-white.svg'} alt="Logo" height="25" className="mr-2" />
            <br/>
            <span> <strong>Whistle Blowing {packageJson.version}</strong> by
            <span className="font-medium ml-2">
                <a href="https://transparency.bg/" target="_blank" rel="noreferrer"><strong>Transparency International Bulgaria</strong> </a>
            &
            <a href="https://happycompany.ltd" target="_blank" rel="noreferrer"> <strong>Happy Company Ltd</strong></a></span>
            </span>
        </div>
    );
}
