import {InputText} from "primereact/inputtext";
import {useLocation} from "react-router-dom";

export const WhistleBlowingSubmitted = () => {

    const location = useLocation()
    const response = location.state.response;
    const printResponse = () => {
        console.log("response : " + response);
        console.log("response : " + response.uuid);
        console.log("response : " + response.secret);
    }

    return(
      <div>
          <label>Returned password</label>
          <h1>{printResponse()}</h1>
          <InputText value={response.uuid} className="wid50"/>
      </div>
    );
}
export default WhistleBlowingSubmitted;