import WhistleBlowing from "./WhistleBlowing";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {LanguageSw} from "../components/partial/LanguageSw";
import {Button} from "primereact/button";
import {AppFooter} from "../AppFooter";
import {layoutColorMode} from "../constants/Constants";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Company} from "../models/Company";

export const WhistleBlowingCheck = () => {

    const {t} = useTranslation();
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    let [company, setCompany] = useState(new Company());

    return (
        <div>
            <LoadingSpinner isLoading={isLoading}/>
            <div className="wb-container">
                <div className="wb-header grid">
                    {/* Logo put here */}
                    <h1 className="col-12 md:col-8 floatLeft">Confidential Reporting System</h1>
                    {/* Language Switch */}
                    <div className="col-12 md:col-4 ">
                        <LanguageSw className="wid80 language-switch floatRight"/>
                    </div>
                </div>
                {/*    Put the companies text here */}
                <div className="card">
                    <div dangerouslySetInnerHTML={{__html: company.text}}/>
                </div>

                <div className="wb-footer grid">
                    <div className="col-12 md:col-6">
                        <Button label={t("add")+" "+t("occurrence")} icon="pi pi-plus"
                                className="floatLeft wid90 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={t("check")+" "+t("occurrence")}
                                className="floatRight wid90 font-bold px-5 py-3 p-button-outlined p-button-raised white-space-nowrap"/>
                    </div>
                </div>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}
export default WhistleBlowingCheck;