import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

var service_path = 'occurrence';

export class OccurrenceService {
    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list", {
            headers:  authHeader()
        });
    }

    getCountByDate(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-date-between/2022-08-01/2022-08-08", {
            headers:  authHeader()
        });
    }

    getCountByStatus(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-status-between/2022-08-01/2022-08-08", {
            headers:  authHeader()
        });
    }
    getStatusCount(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-status", {
            headers:  authHeader()
        });
    }

    async create(occurrence, token) {
        await api.post(BASE_DOMAIN + "api/" + service_path + "/create/" + token, occurrence,
            {headers: authHeader()});
    }
}