import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

var service_path = 'company';
export class CompanyService {
    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list", {
            headers:  authHeader()
        });
    }

    async getFrontend(url){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/get-front/" + url, {
            headers:  authHeader()
        });
    }

    async create(categoryName){
        return api.post( BASE_DOMAIN + "api/"+service_path+"/create",
            categoryName, {headers:  authHeader()});
    }

    async edit(Category){
        return api.put( BASE_DOMAIN + "api/"+service_path+"/update",
            Category , {headers:  authHeader()});
    }
}