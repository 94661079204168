import React from 'react';
import {Route, Routes} from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ForgotPassword } from './pages/ForgotPassword';
import TokenService from "./services/auth.service";
import WhistleBlowing from "./pages/WhistleBlowing";
import WebForm from "./pages/WebForm";
import Company from "./Company";
import WhistleBlowingSubmit from "./pages/WhistleBlowingSubmit";
import WhistleBlowingCheck from "./pages/WhistleBlowingCheck";
import {Router} from "react-router";
import WhistleBlowingSubmitted from "./pages/WhistleBlowingSubmitted";
// import withRouter from "./withRouter";

const AppWrapper = (props) => {

	// if ((props.location.pathname !== '/login' &&
	// 	 props.location.pathname !== '/register' &&
	// 	 props.location.pathname !== '/forgotPassword') && TokenService.getLocalAccessToken() == null){
	// 	return window.location.href = `/login`;
	// }
	// switch (props.location.pathname) {
	// 	case '/login':
	// 		return <Route path="/login" component={Login}/>
	// 	case '/register':
	// 		return <Route path="/register" component={Register}/>
	// 	case '/forgotPassword':
	// 		return <Route path="/forgotPassword" component={ForgotPassword}/>
	// 	case '/logout': {
	// 		return TokenService.logout();
	// 	}
	// 	case '/whistle-blowing/:token/submit':{
	// 		return <Route path="/whistle-blowing/:token/submit" component={WhistleBlowingSubmit}/>
	// 	}
	// 	case '/whistle-blowing/:token/check':{
	// 		return <Route path="/whistle-blowing/:token/check" component={WhistleBlowingCheck}/>
	// 	}
	// 	case '/web-form':{
	// 		return <Route path="/web-form" component={WebForm}/>
	// 	}
	// 	case '/':{
	// 		return <App/>
	// 	}
	// 	default:
	//
	// 		if ( props.location.pathname.equals("/whistle-blowing/:token/submit") ) {
	// 			console.log("/whistle-blowing/:token/submit");
	// 			return <Route path="/whistle-blowing/:token/submit" component={WhistleBlowingSubmit}/>
	// 		} else if ( props.location.pathname.includes("/whistle-blowing") ){
	// 			return <Route path="/whistle-blowing/:token" component={WhistleBlowing}/>;
	// 		} else {
	// 			return <Company/>;
	// 		}
	// }
	return (
		<div>
			<Routes>
				<Route path="/login" component={Login}/>
				<Route path="/register" component={Register}/>
				<Route path="/forgotPassword" component={ForgotPassword}/>
				<Route path="/forgotPassword" component={ForgotPassword}/>
				<Route path="/whistle-blowing/:token/check" component={WhistleBlowingCheck} exact={true}/>
				<Route path="/whistle-blowing/:token/submit" component={WhistleBlowingSubmit} exact={true}/>
				<Route path="/whistle-blowing/:token/submitted" component={WhistleBlowingSubmitted} exact={true}/>
				<Route path="/whistle-blowing/:token" component={WhistleBlowing} exact={true}/>
				<Route path="/web-form" component={WebForm}/>
				<Route path="/company/**" component={Company} exact={true}/>
				<Route path="/" component={App} exact={true}/>
			</Routes>
		</div>
	);
}

export default AppWrapper;