import React, {useEffect, useRef, useState} from "react";
import "primereact/resources/themes/lara-light-teal/theme.css";
// import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import PrimeReact from 'primereact/api';
import {Tooltip} from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import {Route, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";


export const App = () => {

    const {t} = useTranslation();
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    PrimeReact.ripple = true;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const menu = [
        {
            label: 'Home',
            items: [{
                label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/'
            }, {
                label: t('vehicles'), icon: 'pi pi-fw pi-car', to: '/vehicle-list'
            }, {
                label: t('insurances'), icon: 'pi pi-fw pi-shield', to: '/insurances'
            }, {
                label: t('taxes'), icon: 'pi pi-fw pi-dollar', to: '/taxes'
            }, {
                label: t('payments'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-history',
                        to: '/payments'
                    }
                ]
            }, {
                label: t('repairs'), icon: 'pi pi-fw pi-car',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming-repair'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-book',
                        to: '/repair-history'
                    }
                ]
            }, {
                label: t('profile'), icon: 'pi pi-fw pi-user', to: '/profile'
            }, {
                label: t('log_out'), icon: 'pi pi-fw pi-sign-out', to: '/logout'
            }]
        }];

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>

            <div className="grid grid-nogutter surface-0 text-800">
                <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                    <section>
                        <span className="block text-6xl font-bold mb-1">Whistle blowing</span>
                        <div className="text-6xl text-primary font-bold mb-3">Make your life easier</div>
                        <p className="mt-0 mb-4 text-700 line-height-3">Whistle blowing system gives you the ability to
                            change the way the things happening.</p>

                        <Link to="/whistle-blowing" className="textDecorationNone">
                            <Button label="Blow the whistle" type="button" className="mr-3 p-button-raised"/>
                        </Link>

                        <Link to="/check-case" className="textDecorationNone">
                            <Button label="Check case status" type="button" className="p-button-outlined"/>
                        </Link>
                    </section>
                </div>
                <div className="col-12 md:col-6 overflow-hidden">
                    <img src="assets/images/hero-1.png" alt="hero-1" className="md:ml-auto block md:h-full"
                         style={{clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)'}}/>
                </div>
            </div>

            <div className="surface-0 text-700 text-center">
                <div className="text-blue-600 font-bold mb-3"><i className="pi pi-discord"></i>&nbsp;Transparency
                    Bulgaria
                </div>
                <div className="text-900 font-bold text-5xl mb-3">Blow the whistle - Do not waste time</div>
                <div className="text-700 text-2xl mb-5">Whistle blowing system gives you the ability to
                    change the way the things happening.
                </div>
                <Link to="/whistle-blowing" className="textDecorationNone">
                    <Button label="Blow the whistle" icon="pi pi-discord"
                        className="mr-3 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                </Link>

                <Link to="/check-case" className="textDecorationNone">
                    <Button label="Check case status" icon="pi pi-discord"
                        className="font-bold px-5 py-3 p-button-raised p-button-outlined white-space-nowrap"/>
                </Link>
                <div style={{height: "20px"}}/>
            </div>

            <div className="surface-0 text-center">
                <div className="mb-3 font-bold text-2xl">
                    <span className="text-900">One Product, </span>
                    <span className="text-blue-600">Many Solutions</span>
                </div>
                <div className="text-700 text-sm mb-6">Ac turpis egestas maecenas pharetra convallis posuere morbi leo
                    urna.
                </div>
                <div className="grid">
                    <div className="col-12 md:col-4 mb-4 px-5">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-desktop text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">Built for Developers</div>
                        <span className="text-700 text-sm line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
                    </div>
                    <div className="col-12 md:col-4 mb-4 px-5">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-lock text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">End-to-End Encryption</div>
                        <span className="text-700 text-sm line-height-3">Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin aliquam ultrices sagittis.</span>
                    </div>
                    <div className="col-12 md:col-4 mb-4 px-5">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-check-circle text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">Easy to Use</div>
                        <span className="text-700 text-sm line-height-3">Ornare suspendisse sed nisi lacus sed viverra tellus. Neque volutpat ac tincidunt vitae semper.</span>
                    </div>
                    <div className="col-12 md:col-4 mb-4 px-5">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-globe text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">Fast & Global Support</div>
                        <span className="text-700 text-sm line-height-3">Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum tellus.</span>
                    </div>
                    <div className="col-12 md:col-4 mb-4 px-5">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-github text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">Open Source</div>
                        <span className="text-700 text-sm line-height-3">Nec tincidunt praesent semper feugiat. Sed adipiscing diam donec adipiscing tristique risus nec feugiat. </span>
                    </div>
                    <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
            <span className="p-3 shadow-2 mb-3 inline-block" style={{borderRadius: '10px'}}>
                <i className="pi pi-shield text-4xl text-blue-500"></i>
            </span>
                        <div className="text-900 mb-3 font-medium">Trusted Securitty</div>
                        <span className="text-700 text-sm line-height-3">Mattis rhoncus urna neque viverra justo nec ultrices. Id cursus metus aliquam eleifend.</span>
                    </div>
                </div>
            </div>

            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}

export default App;
