export default function authHeader() {
    const access_token = localStorage.getItem('access_token');

    // if (access_token) {
        // Accepted-Language: 'bg'
        return { 'language': 'bg' };
         // return { Authorization: 'Bearer ' + access_token };
    // } else {
    //     return {};
    // }
}