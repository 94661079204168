import {Button} from "primereact/button";
import {AppFooter} from "../AppFooter";
import {useTranslation} from "react-i18next";
import {layoutColorMode} from '../constants/Constants';
import {LanguageSw} from "../components/partial/LanguageSw";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Company} from "../models/Company";
import {CompanyService} from "../services/CompanyService";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {Link} from "react-router-dom";

export const WhistleBlowing = (props) => {

    const {t} = useTranslation();
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    let [company, setCompany] = useState(new Company());

    useEffect(() => {
        setTimeout(async () => {
            const companyService = new CompanyService();
            await companyService.getFrontend(token)
                .then(response => {
                    if (response.status === 200){
                        setCompany(response.data);
                        setIsLoading(false);
                    } else {
                        props.history.push('/login');
                    }
                }).catch(error => {
                    props.history.push('/login');
                });
        }, 1000);
    }, []);

    return (
        <div>
            <LoadingSpinner isLoading={isLoading}/>
            <div className="wb-container">
                <div className="wb-header grid">
                    {/* Logo put here */}
                    <h1 className="col-12 md:col-8 floatLeft">Confidential Reporting System</h1>
                    {/* Language Switch */}
                    <div className="col-12 md:col-4 ">
                        <LanguageSw className="wid80 language-switch floatRight"/>
                    </div>
                </div>
                {/*    Put the companies text here */}
                <div className="card">
                    <div dangerouslySetInnerHTML={{__html: company.text}}/>
                </div>

                <div className="wb-footer grid">
                    <div className="col-12 md:col-6">
                        <Link to={props.location.pathname + "/submit".replaceAll("//", "/")} className="textDecorationNone">
                            <Button label={t("add")+" "+t("occurrence")} icon="pi pi-plus"
                                className="floatLeft wid90 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                        </Link>
                    </div>
                    <div className="col-12 md:col-6">
                        <Link to={props.location.pathname + "/check".replaceAll("//", "/")} className="textDecorationNone">
                            <Button label={t("check")+" "+t("occurrence")}
                                className="floatRight wid90 font-bold px-5 py-3 p-button-outlined p-button-raised white-space-nowrap"/>
                        </Link>
                    </div>
                </div>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}

export default WhistleBlowing;