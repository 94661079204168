import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {UserService} from "../services/UserService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {User} from "../models/User";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import Role from "../models/Role";
import {RoleService} from "../services/RoleService";

export const UserComponent = (props) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([Role]);
    const [newUser, setNewUser] = useState(new User());
    const [createUserDialog, setCreateUserDialog] = useState(false);

    const isMounted = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            loadUserList();
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            loadRoleList();
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const loadUserList = () => {
        const userService = new UserService();
        userService.getList()
            .then(response => {
                setUserList(response.data);
                setLoading(false);
            });
    }

    const loadRoleList = () => {
        const roleService = new RoleService();
        roleService.getList()
            .then(response => {
                setRoleList(response.data);
            });
    }


    const showNewUserDialog = () => {
        setNewUser(new User());
        newUser.roleList = [];
        setCreateUserDialog(true);
    }
    
    const renderNewFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewUserDialog()} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewUser()} style={{margin: "0"}}
                        disabled={newUser.email === '' || newUser.firstName === '' || newUser.lastName === '' ||
                            (newUser.roleList && newUser.roleList.length === 0)} />
            </div>
        );
    }

    const hideNewUserDialog = () => {
        setCreateUserDialog(false);
    }

    const createNewUser = async () => {
        const userService = new UserService();
        await userService.create(newUser).then( response => {
            loadUserList();
            hideNewUserDialog();
        });
    }

    const onNewUserRolesChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...newUser};
        _user[`${name}`] = val;
        setNewUser(_user);
    }

    const handleRowClick = (row) =>{
        props.history.push('/company/user/'+row.value.uuid);
    }

    const nameBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail mb-1">
                    <div className="product-name">{rowData.firstName + " " + rowData.lastName}</div>
                </div>
                <span className="product-price"><i className="pi pi-envelope"/> {rowData.email}</span>
            </React.Fragment>
        );
    }

    const usersBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <span className="text-xl"><i className={`product-name pi pi-${rowData.emailVerified ? 'check text-green-500' : 'times'}`}/> Email verified</span>
                <br/>
                    <span className="text-xl"><i className={`product-name pi pi-${rowData.loginEnabled ? 'check text-green-500' : 'times'}`}/> Login Enabled</span>
                </div>
            </React.Fragment>
        );
    }

    const angleBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-action">
                    <span className={`product-badge`}> <i className="pi pi-angle-right"/></span>
                </div>
            </React.Fragment>
        );
    }

    const onNewInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...newUser};
        _user[`${name}`] = val;

        setNewUser(_user);
    }

    const roleTemplate = (option) => {
        return (
            <div className="user-item wid90">
                <div>{option.name}</div>
            </div>
        );
    }

    const selectedRolesTemplate = (option) => {
        if (option) {
            return (
                <div className="user-item user-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
    }

    return (
        <div className="dataview">
            <div className="p-dataview-header">
                <h1>{t("users")}</h1>
                <Button icon="pi pi-plus" label={t("add")+" "+t("user")} onClick={() => showNewUserDialog()}/>
                <Dialog header={t("add")+" "+t("user")} visible={createUserDialog} onHide={() => hideNewUserDialog()}
                        breakpoints={{'560px': '75vw'}} style={{width: '35vw',minWidth: "400px"}} footer={renderNewFooter()}
                        className="create-user-dialog">
                    <div className="pt-4 grid">
                        <div className="pb-2 col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="firstName" value={newUser.firstName} className="wid100"
                                           onChange={(e) => onNewInputChange( e, 'firstName' )} autoFocus/>
                                <label htmlFor="firstName">{t('first_name')}</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="lastName" value={newUser.lastName} className="wid100"
                                           onChange={(e) => onNewInputChange( e, 'lastName' )} autoFocus/>
                                <label htmlFor="lastName">{t('last_name')}</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6 pt-4">
                            <span className="p-float-label">
                                <InputText id="email" value={newUser.email} className="wid100"
                                           onChange={(e) => onNewInputChange( e, 'email' )} autoFocus/>
                                <label htmlFor="email">{t('email')}</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6 pt-4">
                            <span className="p-float-label">
                                <InputText id="phone" value={newUser.phone} className="wid100"
                                           onChange={(e) => onNewInputChange( e, 'phone' )} autoFocus/>
                                <label htmlFor="phone">{t('phone')}</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-12 pt-4">
                            <p>{t('roleList')}</p>
                            <MultiSelect  options={roleList} value={newUser.roleList}
                                         className="col-12 md:col-12 wid100 multiselect-custom"
                                         onChange={(e) => onNewUserRolesChange(e, 'roleList')}
                                         itemTemplate={roleTemplate} selectedItemTemplate={selectedRolesTemplate}
                                         placeholder={t('select_role')} maxSelectedLabels={5} scrollHeight="300px"/>
                        </div>
                    </div>
                </Dialog>
            </div>
            <div className="card">
                <DataTable value={userList} loading={loading}
                           selectionMode={"single"}
                           onSelectionChange={handleRowClick}>
                    <Column field="name" body={nameBody} style={{ minWidth: '45rem' }} />
                    <Column field="name" body={usersBody} style={{ minWidth: '0.3rem', textAlign: "left" }} />
                    <Column field="name" body={angleBody} style={{ minWidth: '1rem', textAlign: "right" }} />

                </DataTable>
            </div>
        </div>
    );
}