import {useNavigate} from "react-router-dom";
import {LanguageSw} from "../components/partial/LanguageSw";
import {Button} from "primereact/button";
import {AppFooter} from "../AppFooter";
import {layoutColorMode} from "../constants/Constants";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {FileUpload} from "primereact/fileupload";
import {CategoryService} from "../services/CategoryService";
import LanguageUtil from "../util/LanguageUtil";
import {OccurrenceService} from "../services/OccurrenceService";
import Occurrence from "../models/Occurrence";

export const WhistleBlowingSubmit = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const languageUtil = new LanguageUtil();
    const { token } = useParams();
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newOccurrence, setNewOccurrence] = useState(new Occurrence());

    useEffect(() => {
        setTimeout(() => {
            loadList();
        }, 1000);
    }, []);

    const loadList = () => {
        const categoryService = new CategoryService();
        categoryService.getList()
            .then(response => {
                setCategoryList(response.data);
            });
    }

    const createNewOccurrence = async () => {
        newOccurrence.categoryId = selectedCategory.categoryId;
        newOccurrence.source = 'SYSTEM';
        const occurrenceService = new OccurrenceService();
        await occurrenceService.create(newOccurrence, token).then( response => {
            navigate('./submitted',
                {state: response}
            )
            // console.log("Occurrence created " + response)
        });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _occurrence = {...newOccurrence};
        _occurrence[`${name}`] = val;
        setNewOccurrence(_occurrence);
    }
    const validateForm = () => {
        console.log("newOccurrence.subject = " + newOccurrence.subject);
        console.log("newOccurrence.description = " + newOccurrence.description);
        console.log("newOccurrence.categoryId = " + selectedCategory);
        return ((newOccurrence.subject === undefined || newOccurrence.subject === '') ||
            (newOccurrence.description === undefined || newOccurrence.description === '') ||
            (selectedCategory === undefined || selectedCategory === null));
    }
    return (
        <div>
            <div className="wb-container">
                <div className="wb-header grid">
                    {/* Logo put here */}
                    <h1 className="col-12 md:col-8 floatLeft">Confidential Reporting System</h1>
                    {/* Language Switch */}
                    <div className="col-12 md:col-4 ">
                        <LanguageSw className="wid80 language-switch floatRight"/>
                    </div>
                </div>
                <div className="card">
                    <div className="grid">
                        <h2 className="col-12 md:col-12">{t('submit_occurrence')}</h2>
                        <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label htmlFor="subject">{t('title')}</label>
                                <InputText id="subject"  className="wid100"
                                           autoFocus value={newOccurrence.subject}
                                           onChange={(e) => onInputChange( e, 'subject' )}/>
                            </span>
                        </div>
                        <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label htmlFor="description">{t('description')}</label>
                                <InputTextarea id="description"  className="wid100" value={newOccurrence.description}
                                               onChange={(e) => onInputChange( e, 'description' )}/>
                            </span>
                        </div>
                        <div className="col-12 md:col-12 pt-4">
                            <p>{t('categories')}</p>
                            <Dropdown options={categoryList} optionLabel={languageUtil.getLanguage() === "bg-BG" ? "nameBg" : "nameEn"}
                                      className="col-12 md:col-12 wid100 multiselect-custom"
                                      onChange={(e) => setSelectedCategory(e.value)}
                                      value={selectedCategory}
                                      placeholder={t('select_category')} maxSelectedLabels={5} scrollHeight="300px"/>
                        </div>
                        <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label htmlFor="wbName">{t('name')} {"(" + t("optional") + ")"}</label>
                                <InputText id="wbName"  className="wid100" value={newOccurrence.wbName}
                                           onChange={(e) => onInputChange( e, 'wbName' )}/>
                            </span>
                        </div>
                        <div className="col-12 md:col-6 pt-4">
                            <span>
                                <label htmlFor="wbEmail">{t('email')} {"(" + t("optional") + ")"}</label>
                                <InputText id="wbEmail" className="wid100" value={newOccurrence.wbEmail}
                                           onChange={(e) => onInputChange( e, 'wbEmail' )}/>
                            </span>
                        </div>
                        <div className="col-12 md:col-6 pt-4">
                            <span>
                                <label htmlFor="wbPhone">{t('phone')} {"(" + t("optional") + ")"}</label>
                                <InputText id="wbPhone" className="wid100" value={newOccurrence.wbPhone}
                                           onChange={(e) => onInputChange( e, 'wbPhone' )}/>
                            </span>
                        </div>
                        <div className="col-12 md:col-12">
                            <FileUpload name="demo" url="./upload"></FileUpload>
                        </div>

                    </div>
                </div>

                <div className="wb-footer grid">
                    <div className="col-12 md:col-6">
                        <Button label={t("add")+" "+t("occurrence")} onClick={createNewOccurrence}
                                disabled={validateForm()}
                                className="floatLeft wid90 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                    </div>
                </div>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}
export default WhistleBlowingSubmit;