import React, {useEffect, useRef, useState} from "react";
import {OccurrenceService} from "../../services/OccurrenceService";
import {useTranslation} from "react-i18next";
import {Chart} from "primereact/chart";

export const OccurrenceCountByDate = (props) => {

    const isMounted = useRef(false);
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [lineData, setLineData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            loadData().then(r => {
                    isMounted.current = true;
                });
        }, 1000);
    }, []);

    const loadData = async () => {
        const occurrenceService = new OccurrenceService();
        await occurrenceService.getCountByDate()
            .then(response => {
                let i = 0;
                response.data.forEach(row => {
                    labels[i] = row.createdOn;
                    data[i] = row.count;
                    i++;
                });
                setLineData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Брой сигнали по дата',
                            data: data,
                            fill: false,
                            backgroundColor: '#2f4860',
                            borderColor: '#2f4860',
                            tension: 0.4
                        }
                    ]
                });
            });
    }
    return(
        <div>
            <h5>Брой сигнали по дата</h5>
            <Chart type="line" data={lineData} options={props.lineOptions} />
        </div>
    );
}