import {OccurrenceService} from "../services/OccurrenceService";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import '../assets/css/DataView.css';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {OccurrenceDataTable} from "./partial/OccurrenceDataTable";
export const OccurrenceComponent = () => {

    const { t } = useTranslation();
    const [newOccurrence, setNewOccurrence] = useState([]);
    const [displayResponsive, setDisplayResponsive] = useState(false);


    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewOccurrenceDialog()} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewOccurrence()} style={{margin: "0"}} disabled={newOccurrence === ''} />
            </div>
        );
    }

    const showNewOccurrenceDialog = () => {
        setNewOccurrence("");
        dialogFuncMap['displayResponsive'](true);
    }

    const hideNewOccurrenceDialog = () => {
        dialogFuncMap['displayResponsive'](false);
    }

    const handleNewOccurrenceChange = (e) => {
        setNewOccurrence(e.target.value);
    }

    const createNewOccurrence = async () => {
        const occurrenceService = new OccurrenceService();
        await occurrenceService.create(newOccurrence).then(
            hideNewOccurrenceDialog()
        );
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && newOccurrence !== ''){
            createNewOccurrence();
        }
    }

    return (
        <div className="occurrence-container">
            <div className="p-dataview-header">
                <h1>{t("occurrences")}</h1>
                <Button icon="pi pi-plus" label={t("add")+" "+t("occurrence")} onClick={() => showNewOccurrenceDialog()}/>
                <Dialog header="Създаване на категория" visible={displayResponsive} onHide={() => hideNewOccurrenceDialog()}
                        breakpoints={{'560px': '75vw'}} style={{width: '20vw',minWidth: "400px"}} footer={renderFooter()}>
                    <div style={{paddingTop: '15px'}}>
                        <span className="p-float-label">
                            <InputText id="occurrence_name" className="wid100" value={newOccurrence}
                                       onChange={handleNewOccurrenceChange} onKeyPress={handleKeyPress} autoFocus/>
                            <label htmlFor="occurrence_name">Наименование</label>
                        </span>
                    </div>
                </Dialog>
            </div>
                <div className="card">
                    <div>
                        <OccurrenceDataTable/>
                    </div>
                </div>
        </div>
    );
}