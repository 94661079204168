import {Route} from "react-router-dom";
import {useEffect, useState} from "react";
import {CategoryComponent} from "./components/CategoryComponent";
import "primereact/resources/themes/lara-light-teal/theme.css";
import {AppTopbar} from "./AppTopbar";
import {AppFooter} from "./AppFooter";
import {AppMenu} from "./AppMenu";
import {useTranslation} from "react-i18next";
import PrimeReact from 'primereact/api';
import classNames from 'classnames';
import {Dashboard} from "./components/Dashboard";
import {OccurrenceComponent} from "./components/OccurrenceComponent";
import {UserComponent} from "./components/UserComponent";
import {UserEditComponent} from "./components/UserEditComponent";

export const Company = () => {

    const { t } = useTranslation();
    const layoutMode = 'static';
    const layoutColorMode = 'light';
    const inputStyle = 'outlined';
    // const [layoutMode, setLayoutMode] = useState('static');
    // const [layoutColorMode, setLayoutColorMode] = useState('light');
    // const [inputStyle, setInputStyle] = useState('outlined');
    const ripple = true;
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    PrimeReact.ripple = true;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    // useEffect(() => {
    //     copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    // }, [location]);

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const menu = [
        {
            label: 'Home',
            items: [{
                label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/company'
            },{
                label: t('occurrences'), icon: 'pi pi-fw pi-file', to: '/company/occurrence'
            },{
                label: t('categories'), icon: 'pi pi-fw pi-shield', to: '/company/category'
            },{
                label: t('users'), icon: 'pi pi-fw pi-user', to: '/company/users'
            },/*{
                label: t('taxes'), icon: 'pi pi-fw pi-dollar', to: '/company/taxes'
            },{
                label: t('payments'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-history',
                        to: '/payments'
                    }
                ]
            },{
                label: t('repairs'), icon: 'pi pi-fw pi-car',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming-repair'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-book',
                        to: '/repair-history'
                    }
                ]
            },*/{
                label: t('profile'), icon: 'pi pi-fw pi-user', to: '/profile'
            },{
                label: t('log_out'), icon: 'pi pi-fw pi-sign-out', to: '/logout'
            }]
        }];

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                       mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                       onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/company" exact render={() => <Dashboard colorMode={layoutColorMode}/>}/>
                    <Route path="/company/occurrence" component={OccurrenceComponent}/>
                    <Route path="/company/category" component={CategoryComponent}/>
                    <Route path="/company/user/:uuid" component={UserEditComponent}/>
                    <Route path="/company/users" component={UserComponent}/>
                    {/*<Route path="/payments" component={PaymentDataTable}/>*/}
                    {/*<Route path="/upcoming" component={UpcomingDataTable}/>*/}
                    {/*<Route path="/repair-history" component={RepairHistoryDataTable}/>*/}
                    {/*<Route path="/login" component={Login}/>*/}
                    {/*<Route path="/register" component={Register}/>*/}
                </div>
                <AppFooter layoutColorMode={layoutColorMode}/>
            </div>
        </div>
    );
}

export default Company;