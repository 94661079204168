import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ReCAPTCHA from "react-google-recaptcha";
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import '../assets/css/Form.css';
import {Link} from "react-router-dom";
import {AppFooter} from "../AppFooter";
import {RECAPTCHA_SITE_KEY} from "../Constants";

export const ForgotPassword = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const defaultValues = {
        email: '',
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = async (data) => {
        setLoading(true);
        setFormData(data);
        setTimeout(() => {
            setShowMessage(true);
            setLoading(false);
        }, 2000);

        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    return (
        <div className="form-holder">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}/>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account is registered under name <b>{formData.name}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> for activation instructions.
                    </p>
                </div>
            </Dialog>

            <div className="flex justify-content-center">
                <div className="card">
                    <img src={t('logo_vertical_url')} alt="CarFile logo" height={250} className="marginAuto" />
                    <h1 className="text-900 text-2xl">{t('forgot_password')}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="email" control={control}
                                            rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    disabled={loading}/>
                                            )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email*</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="p-field">
                            <span className="p-float-label p-input-icon-right">
								<Controller name="captcha" control={control}
                                    rules={{ required: 'Re Captcha is required.'}}
                                            render={({ field, fieldState }) => (
                                                <ReCAPTCHA id={field.name} {...field}
                                                           sitekey={RECAPTCHA_SITE_KEY}
                                                         className={classNames({ 'p-invalid': fieldState.invalid })}/>
                                            )} />
							</span>
                            {getFormErrorMessage('captcha')}
                        </div>

                        <div className="field">
                            <Button type="submit" label={t('password_request')} className="mt-2"
                                    loading={loading} onClick={handleSubmit(onSubmit)}/>
                        </div>
                        <div className="field">
                            <div className="wid45 floatLeft">
                                <Link to="/login" className="textDecorationNone"><Button type="button" label="Go to Login" className="p-button"
                                                                                         style={{backgroundColor:"var(--pink-400)", borderColor:"var(--pink-400)"}} />
                                </Link>
                            </div>
                            <div className="wid45 floatRight">
                                <Link to="/register" className="textDecorationNone"><Button type="button" label="Go to Sign Up" className="p-button"
                                                                                            style={{backgroundColor:"var(--yellow-400)", borderColor:"var(--yellow-400)"}} />
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <AppFooter layoutColorMode='light'/>
        </div>
    );
}
