import {useTranslation} from "react-i18next";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import React, {useEffect, useState} from "react";
import Role from "../models/Role";
import {RoleService} from "../services/RoleService";
import {UserService} from "../services/UserService";
import {useParams} from "react-router";
import {Button} from "primereact/button";
import User from "../models/User";
import dateFormat from 'dateformat';
import {DATE_FORMAT} from "../Constants";
import {InputSwitch} from "primereact/inputswitch";

export const UserEditComponent = (props) => {

    const { t } = useTranslation();
    const [roleList, setRoleList] = useState([Role]);
    const [selectedUser, setSelectedUser] = useState(new User());
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            loadSelectedUser();
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
            loadRoleList();
        }, 1000);
    }, [loading]);

    const loadSelectedUser = () => {
        const userService = new UserService();
        userService.getUserByUUID(uuid)
            .then(response => {
                setSelectedUser(response.data);
            });
    }

    const loadRoleList = () => {
        const roleService = new RoleService();
        roleService.getList()
            .then(response => {
                setRoleList(response.data);
            });
    }

    const editUser = async () => {
        const userService = new UserService();
        await userService.edit(selectedUser).then( response => {
            redirectToListPage();
        });
    }

    const onEditInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...selectedUser};
        _user[`${name}`] = val;

        setSelectedUser(_user);
    }

    const redirectToListPage = () => {
        props.history.push('/company/users');
    }

    const onRolesChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...selectedUser};
        _user[`${name}`] = val;
        setSelectedUser(_user);
    }

    const roleTemplate = (option) => {
        return (
            <div className="user-item wid90">
                <div>{option.name}</div>
            </div>
        );
    }

    const selectedRolesTemplate = (option) => {
        if (option) {
            return (
                <div className="user-item user-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
    }

    const changeLoginStatus = (e) => {
        const val = !selectedUser.loginEnabled;
        console.log("Changing status of login to : " + val);
        console.log("Changing status of login from : " + selectedUser.loginEnabled);
        let _user = {...selectedUser};
        _user["loginEnabled"] = val;
        setSelectedUser(_user);
    }

    return(
        <div className="dataview" loading>
            <Button className="p-button-link" icon="pi pi-arrow-left" label={t("users")} onClick={() => redirectToListPage()}/>
            <div className="p-dataview-header">
                <h1>{selectedUser.firstName + " " + selectedUser.lastName}</h1>
            </div>
            <div className="card">
                <div className="pt-4 grid">
                    <div className="pb-2 col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="firstName" value={selectedUser.firstName} className="wid100"
                                           onChange={(e) => onEditInputChange( e, 'firstName' )} autoFocus/>
                                <label htmlFor="firstName">{t('first_name')}</label>
                            </span>
                    </div>
                    <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="lastName" value={selectedUser.lastName} className="wid100"
                                           onChange={(e) => onEditInputChange( e, 'lastName' )} autoFocus/>
                                <label htmlFor="lastName">{t('last_name')}</label>
                            </span>
                    </div>
                    <div className="col-12 md:col-6 pt-4">
                            <span className="p-float-label">
                                <InputText id="email" value={selectedUser.email} className="wid100"
                                           onChange={(e) => onEditInputChange( e, 'email' )} autoFocus/>
                                <label htmlFor="email">{t('email')}</label>
                            </span>
                    </div>
                    <div className="col-12 md:col-6 pt-4">
                            <span className="p-float-label">
                                <InputText id="phone" value={selectedUser.phone} className="wid100"
                                           onChange={(e) => onEditInputChange( e, 'phone' )} autoFocus/>
                                <label htmlFor="phone">{t('phone')}</label>
                            </span>
                    </div>
                    <div className="col-12 md:col-6 pt-4">
                        <p>{t('roles')}</p>
                        <MultiSelect options={roleList} value={selectedUser.roleList} loading={loading}
                                     className="col-12 md:col-12 wid100 multiselect-custom"
                                     onChange={(e) => onRolesChange(e, 'roleList')}
                                     itemTemplate={roleTemplate} selectedItemTemplate={selectedRolesTemplate}
                                     placeholder={t('select_role')} maxSelectedLabels={5} scrollHeight="300px"/>
                    </div>
                    <div className="col-12 md:col-6 pt-4">
                        <span className="text-xl mb-3"><i className={`product-name pi pi-calendar text-green-500`}/>
                            { " " + t('creation_date') + " "} <strong>{dateFormat(selectedUser.registrationDate, DATE_FORMAT)}</strong>
                        </span>
                        <div className="mb-3"/>
                        <span className="text-xl">
                            <i className={`product-name pi pi-${selectedUser.emailVerified ? 'check text-green-500' : 'times'}`}/> Email verified</span>
                        <div className="mb-2"/>
                        <span className="text-xl md:vertical-align-super"><i className={`product-name pi pi-${selectedUser.loginEnabled ? 'check text-green-500' : 'times'}`}/>
                            {" " + t('login') + " " + `${selectedUser.loginEnabled ? t('enabled') : t('disabled')}`}</span>
                        <Button label={`${selectedUser.loginEnabled ? t('disable') : t('enable')}` + " " + t('login')}
                                className={`p-button-${selectedUser.loginEnabled ? 'danger' : 'success'} p-button-outlined p-2 ml-3`}
                        onClick={e => changeLoginStatus(e)}/>
                    </div>

                    <div className="col-12 md:col-12 pt-4 md:text-right">
                        <Button label={t('cancel')} icon="pi pi-times" onClick={() => redirectToListPage()} className="p-button-text" />
                        <Button label={t('save')} icon="pi pi-check" onClick={() => editUser()} style={{margin: "0"}}
                                disabled={selectedUser.email === '' || selectedUser.firstName === '' || selectedUser.lastName === '' ||
                                    selectedUser.roleList === []} />
                    </div>
                </div>
            </div>
        </div>
    );
}