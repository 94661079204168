
export class Occurrence {
    constructor(subject, description, categoryId, wbName, wbEmail, wbPhone, source) {
        this.subject = subject;
        this.description = description;
        this.categoryId = categoryId;
        this.wbName = wbName;
        this.wbEmail = wbEmail;
        this.wbPhone = wbPhone;
        this.source = source;
    }
}

export default Occurrence;